import { isNil } from 'lodash';

export const tierTypes = {
  TIER_1: 1,
  1: 'TIER_1',
  TIER_2: 2,
  2: 'TIER_2',
  TIER_3: 3,
  3: 'TIER_3',
  TIER_4: 4,
  4: 'TIER_4',
};

export const stateTypes = {
  PREGAME: 'PREGAME',
  IN_BREAK: 'IN_BREAK',
  IN_PLAY: 'IN_PLAY',
};

export const createTierColumns = () => ({
  [tierTypes.TIER_1]: {
    id: tierTypes.TIER_1,
    label: 'Tier 1',
    shortLabel: 'T1',
  },
  [tierTypes.TIER_2]: {
    id: tierTypes.TIER_2,
    label: 'Tier 2',
    shortLabel: 'T2',
  },
  [tierTypes.TIER_3]: {
    id: tierTypes.TIER_3,
    label: 'Tier 3',
    shortLabel: 'T3',
  },
  [tierTypes.TIER_4]: {
    id: tierTypes.TIER_4,
    label: 'Tier 4',
    shortLabel: 'T4',
  },
});

export const createStateRules = ({
  matchState,
  inPrematch,
  inBreak,
  hideIndicators,
}) => {
  const stateRules = {
    [stateTypes.PREGAME]: {
      id: stateTypes.PREGAME,
      field: 'preMatch',
      label: 'Pregame',
      active: inPrematch,
    },
    [stateTypes.IN_BREAK]: {
      id: stateTypes.IN_BREAK,
      field: 'inBreak',
      label: 'In-break',
      active: !inPrematch && inBreak,
      disabled: true,
      hint: 'Values are the same as Pregame values',
    },
    [stateTypes.IN_PLAY]: {
      id: stateTypes.IN_PLAY,
      field: 'inPlay',
      label: 'In-play',
      active: !inBreak && !inPrematch,
    },
  };

  if (isNil(inPrematch) && isNil(inBreak)) {
    stateRules[stateTypes.PREGAME].active = undefined;
    stateRules[stateTypes.IN_BREAK].active = undefined;
    stateRules[stateTypes.IN_PLAY].active = undefined;
  }

  if (matchState === 'FINISHED') {
    stateRules[stateTypes.PREGAME].active = false;
    stateRules[stateTypes.IN_BREAK].active = false;
    stateRules[stateTypes.IN_PLAY].active = false;
  }

  if (hideIndicators) {
    stateRules[stateTypes.PREGAME].active = null;
    stateRules[stateTypes.IN_BREAK].active = null;
    stateRules[stateTypes.IN_PLAY].active = null;
  }

  return stateRules;
};

// NOTE: Remove once old sport templates are removed
export const parseLimitConfiguration = (limitConfiguration) => {
  if (limitConfiguration && limitConfiguration.type !== 'NO_CONFIG') return limitConfiguration;

  return {
    type: 'SIMPLE_TIERED',
    tiers: {
      1: {
        type: 'SIMPLE_TIERED',
        inPlay: 1,
        inBreak: 1,
        preMatch: 1,
      },
      2: {
        type: 'SIMPLE_TIERED',
        inPlay: 1,
        inBreak: 1,
        preMatch: 1,
      },
      3: {
        type: 'SIMPLE_TIERED',
        inPlay: 1,
        inBreak: 1,
        preMatch: 1,
      },
      4: {
        type: 'SIMPLE_TIERED',
        inPlay: 1,
        inBreak: 1,
        preMatch: 1,
      },
    },
  };
};

export const calculateAfterOperationValue = (operation, currentValue, min = 0, max = 100, stepValue = 0.5) => {
  const numberCurrentValue = Number(currentValue);
  let newValue;
  if (operation === '-') {
    if (numberCurrentValue - stepValue < min) {
      newValue = min;
    } else {
      newValue = Number(parseFloat(numberCurrentValue - stepValue).toFixed(2));
    }
    return newValue;
  }
  if (numberCurrentValue + stepValue > max) {
    newValue = max;
  } else {
    newValue = Number(parseFloat(numberCurrentValue + stepValue).toFixed(2));
  }
  return newValue;
};
