<template>
  <transition name="hierarchy-sidebar-slide">
    <Sidebar
      :expanded="expanded"
      @update:expanded="setExpanded"
      class="hierarchy-sidebar"
    >
      <div
        ref="contentElement"
        class="hierarchy-sidebar__content"
      >
        <div
          v-if="isSportSelected"
          class="hierarchy-sidebar__content-actions"
        >
          <button
            class="hierarchy-sidebar__button"
            type="button"
            @click="goBack"
          >
            <Icon
              name="chevron-left"
            />
            Back
          </button>
          <div class="hierarchy-sidebar__form-group">
            <div class="hierarchy-sidebar__form-label">
              Region
            </div>
            <div class="hierarchy-sidebar__form-control">
              <RegionSelect
                :value="currentRegionId"
                :options="allRegions"
                @update:value="setRegionId"
              />
            </div>
          </div>
        </div>
        <div
          v-if="loading"
          class="hierarchy-sidebar__content-loader"
        >
          <Spinner />
        </div>
        <div
          v-else
          class="hierarchy-sidebar__content-container"
        >
          <SidebarButton
            v-for="option in allOptions"
            :key="option.id"
            :title="option.name"
            :selected="selectedOptionId === option.id"
            @click="selectOptionId(option.id)"
            @scroll-to="scrollToOption"
          >
            {{ option.name }}
          </SidebarButton>
        </div>
      </div>
    </Sidebar>
  </transition>
</template>

<script>
import {
  ref, computed, watch,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import {
  pickBy, identity, filter, map,
} from 'lodash';
import { allDisplayModes } from '@/services/helpers/hierarchy-sidebar';
import Icon from '@/components/common/Icon';
import Spinner from '@/components/common/Spinner';
import Sidebar from '@/components/common/Sidebar';
import SidebarButton from '@/components/common/SidebarButton';
import RegionSelect from '@/components/events/RegionSelect';

export default {
  components: {
    Icon,
    Spinner,
    Sidebar,
    SidebarButton,
    RegionSelect,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const contentElement = ref(null);
    const searchQuery = ref('');
    const currentSportId = computed(() => route.query.sport || '');
    const currentRegionId = computed(() => route.query.region || '');
    const currentCompetitionId = computed(() => route.query.competition || '');
    const isSportSelected = computed(() => !!currentSportId.value);
    const allSports = computed(() => {
      let sports = [];
      sports = filter(store.getters.sidebarSports, (sport) => sport.sportId !== '');
      return map(sports, ({ sportId, sportName }) => ({ id: sportId, name: sportName }));
    });
    const allRegions = computed(() => {
      // We filter regions by truthy regionId because of edge cases on dev environment when region is null
      const regions = filter(store.getters.sidebarRegionsBySportId(currentSportId.value), ({ regionId }) => !!regionId);
      return map(regions, ({ regionId, regionName }) => ({ id: regionId, name: regionName }));
    });
    const allCompetitions = computed(() => {
      const competitions = store.getters.sidebarCompetitionsBySportIdAndRegionId(currentSportId.value, currentRegionId.value);
      return map(competitions, ({ competitionId, competitionName }) => ({ id: competitionId, name: competitionName }));
    });
    const allOptions = computed(() => (isSportSelected.value ? allCompetitions.value : allSports.value));
    const selectedOptionId = computed(() => (isSportSelected.value ? currentCompetitionId.value : currentSportId.value));
    const loading = computed(() => store.getters.sidebarLoading);

    const setHierarchy = ({ sportId = '', regionId = '', competitionId = '' } = {}) => {
      searchQuery.value = '';
      router.push({
        name: route.name,
        query: pickBy({
          ...route.query,
          sport: sportId,
          region: regionId,
          competition: competitionId,
        }, identity),
      });
    };
    const setRegionId = (regionId) => {
      if (currentRegionId.value === regionId) return;
      router.push({
        name: route.name,
        query: pickBy({
          ...route.query,
          region: regionId,
          competition: '',
        }, identity),
      });
      contentElement.value.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };
    const selectOptionId = (id) => {
      const key = (isSportSelected.value ? 'competition' : 'sport');
      router.push({
        name: route.name,
        query: pickBy({
          ...route.query,
          [key]: id,
        }, identity),
      });
    };
    const goBack = () => {
      setHierarchy();
    };
    const scrollToOption = (offset) => {
      if (!contentElement.value) return;
      contentElement.value.scrollTo({
        top: offset - 99 - 90 - 33 - 8,
        behavior: 'smooth',
      });
    };

    watch(
      [currentSportId, currentRegionId, currentCompetitionId],
      (
        [newSportId, newDisplayModeId, newRegionId, newCompetitionId],
        [oldSportId = '', oldDisplayModeId = ''] = [],
      ) => {
        store.dispatch('setSidebarSelection', {
          sportId: newSportId,
          regionId: newRegionId,
          competitionId: newCompetitionId,
        });
        if (
          oldSportId
            && oldSportId === newSportId
            && oldDisplayModeId === newDisplayModeId
        ) return;
        store.dispatch('loadSidebar');
      },
      { immediate: true },
    );

    const expanded = computed(() => store.getters.sidebarExpanded);
    const setExpanded = (newExpanded) => {
      store.dispatch('setSidebarExpanded', newExpanded);
    };

    return {
      contentElement,
      searchQuery,
      allDisplayModes,
      currentSportId,
      currentRegionId,
      currentCompetitionId,
      isSportSelected,
      allSports,
      allRegions,
      allCompetitions,
      allOptions,
      selectedOptionId,
      loading,
      setHierarchy,
      setRegionId,
      selectOptionId,
      goBack,
      scrollToOption,
      expanded,
      setExpanded,
    };
  },
};
</script>

  <style lang="scss">
  .hierarchy-sidebar {
    padding-top: 24px;
    &__form {
      &-group {
        display: flex;
        flex-direction: column;
        gap: 2px;
      }

      &-label {
        color: #6D6D6D;
        text-transform: uppercase;
        font-family: 'Rubik', sans-serif;
        font-weight: 600;
        font-size: 10px;
        line-height: 11.85px;
      }

      &-control {
      }
    }

    &__header {
      display: flex;
      flex-direction: column;
      padding: 0 8px 12px;
      gap: 8px;
      border-bottom: 1px solid #F0F0F0;
    }

    &__content {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      background-color: #FAFAFA;
      overflow-y: auto;

      &-actions {
        display: flex;
        flex-direction: column;
        padding: 8px 8px 0;
        gap: 4px;
        position: sticky;
        top: 0;
        background-color: inherit;

        &::before {
          content: "";
          background-color: inherit;
          top: 100%;
          height: 8px;
          position: absolute;
          left: -8px;
          width: calc(100% + 8px);
        }
      }

      &-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 32px 0;
      }

      &-container {
        padding:8px 0 16px;
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }

    &__button {
      display: flex;
      align-items: center;
      gap: 4px;
      font-family: 'Rubik', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #191414;
      padding: 4px 8px;
      width: 100%;
      height: 32px;
      border-radius: 4px;

      &:hover {
        background-color: #f0f0f0;
      }

      .icon {
        stroke: #191414;
      }
    }
  }
  </style>
